import { useCallback, useState } from 'react';

import useStore from './useStore';
import { supabase } from './utils';

const useCreatePlayer = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const players = useStore((state) => state.players);
  const setPlayers = useStore((state) => state.setPlayers);

  return useCallback(
    async (playerName: string) => {
      setLoading(true);

      const playerData = {
        name: playerName,
      };

      await supabase
        .from('players')
        .insert(playerData)
        .select()
        .then((response) => {
          if (response.data) {
            const newPlayers = [...players, response.data[0]];
            return setPlayers(newPlayers);
          }

          setLoading(false);
          setError(false);
        });

      return { loading, error };
    },
    [error, loading, setPlayers]
  );
};

export default useCreatePlayer;
