import './CategorySummary.css';
import useStore from './useStore';

const CategorySummary = ({ categoryId }: { categoryId: number }) => {
  const categories = useStore((state) => state.categories);
  const roundGroups = useStore((state) => state.roundGroups);
  const matches = useStore((state) => state.matches);

  const category = categories.find((c) => c.id === categoryId);

  let counts: { groupsCount: number; matchesCount: number } = {
    groupsCount: 0,
    matchesCount: 0,
  };

  const filteredGroups = [
    //@ts-expect-error
    ...new Set(
      roundGroups
        .filter((g) => g.categoryId === categoryId)
        .map((g) => g.groupId)
    ),
  ];
  const filteredMatches = filteredGroups.reduce((acc, current) => {
    const groupMatches = matches.filter((m) => m.groupId === current);
    return acc + groupMatches.length;
  }, 0);

  counts = {
    groupsCount: filteredGroups.length,
    matchesCount: filteredMatches,
  };

  return (
    <>
      {category && (
        <>
          <div className='categoryName'>{category.name}</div>
          <div className='categoryDetails'>
            {counts.groupsCount} {counts.groupsCount === 1 ? 'Group' : 'Groups'}{' '}
            {counts.matchesCount}{' '}
            {counts.matchesCount === 1 ? 'Match' : 'Matches'}
          </div>
        </>
      )}
    </>
  );
};

export default CategorySummary;
