import { Button, Card, Pane, Spinner } from 'evergreen-ui';
import './TournamentSchedule.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import { supabase } from './utils';
import { useEffect, useState } from 'react';
import Ribbon from './Ribbon';

type Schedule = {
  id: number;
  categoryName: string;
  playersList: string[];
  date: string;
  time: string;
  order: number;
  tags: string[];
};

const getSchedule = async (): Promise<Schedule[]> => {
  const { data, error } = await supabase.from('schedule').select();

  if (error) {
    throw new Error(error.message);
  }

  return data as Schedule[];
};

function TournamentSchedule() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState<Schedule[]>([]);
  const [error, setError] = useState(false);

  const [tags, setTags] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string>('All');

  const [filtered, setFiltered] = useState<Schedule[]>([]);

  useEffect(() => {
    setLoading(true);
    getSchedule()
      .then((schedule) => {
        setSchedule(schedule.sort((a, b) => a.order - b.order));
        const allTags: string[] = [
          'All',
          'Juniors',
          'Seniors',
          "Men's",
          "Women's",
          'Mixed',
          'Friday',
          'Saturday',
          'Sunday',
        ];
        // schedule.forEach((s) => {
        //   s.tags.forEach((tag) => {
        //     if (!allTags.includes(tag)) {
        //       allTags.push(tag);
        //     }
        //   });
        // });
        setTags(allTags);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (schedule.length === 0) {
      return;
    }
    if (selectedTag === 'All') {
      setFiltered(schedule);
    } else {
      setFiltered(schedule.filter((s) => s.tags.includes(selectedTag)));
    }
  }, [schedule, selectedTag]);

  if (loading) {
    return (
      <Pane>
        <Spinner marginX='auto' marginY={120} />
      </Pane>
    );
  }

  return (
    <div className='tournament-schedule'>
      <div className='home-page-image-container'>
        <img src='/logo.png' height='100px'></img>
      </div>
      <Ribbon
        text={'Timetables and Registrations'}
        showBackButton={false}
      ></Ribbon>
      <ul className='cloud' role='navigation'>
        {tags.map((tag) => {
          return (
            <li>
              <Button
                className={
                  selectedTag === tag ? 'cloud-button1-active' : 'cloud-button1'
                }
                onClick={() => {
                  setSelectedTag(tag);
                }}
              >
                {tag}
              </Button>
            </li>
          );
        })}
      </ul>
      <div className='tournament-schedule-reminders'>
        Dates and times are tentative. Please arrive 15 minutes before the
        scheduled time.
      </div>
      <div className='tournament-schedule-wrapper'>
        <div className='tournament-schedule-categories'>
          {filtered.map((s) => {
            const dedupedPlayers = Array.from(new Set(s.playersList)).sort();
            return (
              <div className='tournament-schedule-category'>
                <div className='tournament-schedule-category-name'>
                  {s.categoryName}
                </div>
                <div className='tournament-schedule-category-date'>
                  {s.date} {s.time}
                  <br></br>
                  {dedupedPlayers.length} players
                </div>

                <div className='tournament-schedule-category-players'>
                  {dedupedPlayers.map((player) => {
                    return (
                      <div
                        className={
                          player.includes('partner')
                            ? 'tournament-schedule-category-player-highlight'
                            : 'tournament-schedule-category-player'
                        }
                      >
                        {player}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TournamentSchedule;
