import ConfettiExplosion from 'react-confetti-explosion';
import useStore from './useStore';
import './Winners.css';
import { motion } from 'framer-motion'; // for animations

const Winners = ({ categoryId }: { categoryId: number }) => {
  const finals = useStore((state) => state.finals);
  const players = useStore((state) => state.players);
  const finalsForCategory = finals
    .filter((f) => f.categoryId === categoryId)
    .sort((a, b) => a.id - b.id);

  if (finalsForCategory.length === 0) {
    return null;
  }
  const bothFinalsCompleted = finalsForCategory.every((f) => f.completed);

  if (!bothFinalsCompleted) {
    return null;
  }

  const goldSilverMatch = finalsForCategory.find(
    (f) => f.matchIdentifier === 0
  );
  const bronzeMatch = finalsForCategory.find((f) => f.matchIdentifier === 1);

  const goldWinner = players.find(
    (p) => p.id === Number(goldSilverMatch!.winner)
  )!.name;

  const silverWinner = players.find(
    (p) => p.id === Number(goldSilverMatch!.loser)
  )!.name;

  const bronzeWinner = players.find(
    (p) => p.id === Number(bronzeMatch!.winner)
  )!.name;

  const medals = ['🥇', '🥈', '🥉'];
  const winnersList = [
    { position: 1, name: goldWinner, medal: medals[0], size: '180px' },
    { position: 2, name: silverWinner, medal: medals[1], size: '150px' },
    { position: 3, name: bronzeWinner, medal: medals[2], size: '130px' },
  ];

  const WinnerCard = ({ winner }: { winner: (typeof winnersList)[0] }) => {
    const { position, name, medal, size } = winner;
    return (
      <motion.div
        className='winner-card'
        style={{ width: size, height: size }}
        transition={{
          // duration: 0.5,
          // ease: 'easeOut',
          repeatType: 'reverse',
          // repeat: Infinity,
          // repeatDelay: 3,
        }}
        animate={{
          scale: [1, 1.1, 1],
        }}
      >
        <div className={`position position-${position}`}>{medal}</div>
        <div className='name'>{name}</div>
      </motion.div>
    );
  };

  return (
    <div className='winners-container'>
      <div className='winners-list'>
        <ConfettiExplosion particleCount={400} duration={2500} force={0.8} />

        {winnersList.map((winner) => (
          <WinnerCard key={winner.position} winner={winner} />
        ))}
      </div>
    </div>
  );
};

export default Winners;
