import {
  Button,
  Card,
  SelectField,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import useSaveData from './useSaveData';
import { Category } from './types';
import './NewRound.css';
import buildMatches from './buildMatches';
import { useNavigate, useParams } from 'react-router-dom';
import PlayerDropdown from './PlayerDropdown';

const NewRound: FunctionComponent = () => {
  const { categoryId } = useParams();
  const [categoryName, setCategoryName] = useState('');
  const [type, setType] = useState('doubles');
  const [tourney, setTourney] = useState('');
  const [numberOfPlayers, setNumberOfPlayers] = useState(0);
  const [numberOfGroups, setNumberOfGroups] = useState(0);
  const [playTill, setPlayTill] = useState(0);
  const [winBy, setWinBy] = useState(0);
  const [showPlayers, setShowPlayers] = useState(false);

  const [roundPlayers, setRoundPlayers] = useState<number[]>([]);
  const navigate = useNavigate();
  const totalRoundPlayers = roundPlayers.reduce((prev, acc) => prev + acc, 0);

  const [players, setPlayers] = useState<number[]>([]);
  const { loading, error, saveData } = useSaveData();

  const handleConfirm = useCallback(async () => {
    try {
      await buildMatches({
        numberOfGroups,
        numberOfPlayers,
        roundPlayers,
        playerIds: players,
        type,
        roundName: categoryName,
        tourney,
        playTill,
        winBy,
        categoryId: Number(categoryId),
      });
      toaster.success('New category has been created');
      navigate('/');
    } catch {
      toaster.danger('Error creating new category');
    }
  }, [
    numberOfGroups,
    numberOfPlayers,
    roundPlayers,
    players,
    type,
    categoryName,
  ]);

  const DialogContent = (
    <>
      <TextInputField
        required
        label='Category Name'
        name='category-name'
        value={categoryName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShowPlayers(false);
          setCategoryName(e.target.value);
        }}
      />

      <SelectField
        label='Type'
        required
        value={type}
        onChange={(e) => {
          setType(e.target.value);
          if (e.target.value === 'semis') {
            setNumberOfGroups(1);
            setNumberOfPlayers(4);
          }
        }}
      >
        <option value='doubles' selected>
          Doubles
        </option>
        <option value='singles'>Singles</option>
        <option value='teams'>Teams</option>
        <option value='semis'>Semi's</option>
      </SelectField>

      <TextInputField
        required
        label='Number of players'
        name='number-of-players'
        value={numberOfPlayers}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShowPlayers(false);
          setNumberOfPlayers(Number(e.target.value));
        }}
      />

      <TextInputField
        required
        label='Number of groups '
        name='number-of-groups'
        value={numberOfGroups}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShowPlayers(false);
          setNumberOfGroups(Number(e.target.value));
        }}
      />

      <TextInputField
        required
        label='Play Till'
        name='play-till'
        value={playTill}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShowPlayers(false);
          setPlayTill(Number(e.target.value));
        }}
      />
      <TextInputField
        required
        label='Win By'
        name='win-by'
        value={winBy}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setShowPlayers(false);
          setWinBy(Number(e.target.value));
        }}
      />

      <div className='groupPlayerContainer'>
        {numberOfGroups > 0 &&
          Array(numberOfGroups)
            .fill('')
            .map((_, index) => (
              <TextInputField
                label={`Group ${index + 1}`}
                style={{ width: '30%' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setRoundPlayers((prev) => {
                    const newArray = [...prev];
                    newArray[index] = Number(e.target.value);
                    return newArray;
                  })
                }
                value={roundPlayers[index]}
              ></TextInputField>
            ))}
      </div>

      <Button
        appearance='primary'
        onClick={() => {
          setShowPlayers(true);
          setPlayers([]);
        }}
        disabled={
          !categoryName ||
          !numberOfPlayers ||
          !numberOfGroups ||
          totalRoundPlayers !== numberOfPlayers ||
          roundPlayers.length !== numberOfGroups
        }
      >
        Ready to add players
      </Button>
    </>
  );

  const PlayerContent = (
    <>
      <div className='groupPlayerContainer'>
        {Array(numberOfPlayers)
          .fill('')
          .map((_, index) => {
            return (
              <PlayerDropdown
                onChange={(id) => {
                  setPlayers((prev) => {
                    const newArray = [...prev];
                    newArray[index] = id;
                    return newArray;
                  });
                }}
              />
            );
          })}
      </div>
      <Button
        appearance='primary'
        onClick={handleConfirm}
        disabled={
          !categoryName ||
          !numberOfPlayers ||
          !numberOfGroups ||
          totalRoundPlayers !== numberOfPlayers ||
          roundPlayers.length !== numberOfGroups ||
          players.length !== numberOfPlayers
          // ||
          // players.some((p) => p === '')
        }
      >
        Generate Matches
      </Button>
    </>
  );

  return (
    <>
      <h1>New Category</h1>
      <div className='newCategoryContainer'>
        <Card elevation={1} className='card'>
          {DialogContent}
        </Card>
        <Card elevation={1} className='card'>
          {/* {showPlayers && PlayerContent} */}
          {PlayerContent}
        </Card>
      </div>
    </>
  );
};

export default NewRound;
