import { useCallback, useState } from 'react';
import { TABLE } from './types';
import { supabase } from './utils';

const useSaveData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const saveData = useCallback((table: TABLE, rows: any[]) => {
    setLoading(true);
    return supabase
      .from(table)
      .insert(rows)
      .select()
      .then((data) => {
        setLoading(false);
        return data;
      });
  }, []);

  return { loading, error, saveData };
};

export default useSaveData;
