import { Combobox, Dialog } from 'evergreen-ui';
import useStore from './useStore';
import './PlayerDropdown.css';
import useCreatePlayer from './useCreatePlayer';
import { useState } from 'react';

const PlayerDropdown = ({ onChange }: { onChange: (id: number) => void }) => {
  const players = useStore((state) => state.players);
  const createPlayer = useCreatePlayer();

  const [showAddPlayer, setShowAddPlayer] = useState(false);
  const [playerName, setPlayerName] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      await createPlayer(playerName);
      setShowAddPlayer(false);
    } catch {
      console.log('Error creating player');
    }
  };

  return (
    <div className='playerDropdown-container'>
      <Combobox
        items={players}
        itemToString={(item) => (item ? item.name : '')}
        onChange={(selected) => onChange(selected.id)}
      />
      <div className='playdropdown-add' onClick={() => setShowAddPlayer(true)}>
        +
      </div>

      <Dialog
        isShown={showAddPlayer}
        title='Creating player'
        onConfirm={handleSubmit}
        isConfirmLoading={loading}
      >
        <input
          type='text'
          name='Player Name'
          onChange={(e) => setPlayerName(e.target.value)}
        />
      </Dialog>
    </div>
  );
};

export default PlayerDropdown;
