import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Match } from './types';
import { supabase } from './utils';
import { Button, Pane, Spinner } from 'evergreen-ui';
import './Matches.css';
import { AppContext } from './AppContext';
import useLoadAllData from './useLoadAllData';
import MatchComponent from './MatchComponent';
import { useReactToPrint } from 'react-to-print';
import Ribbon from './Ribbon';
import useStore from './useStore';

const Matches = () => {
  const componentRef = useRef(null);
  const { id: groupId, user } = useParams();

  const players = useStore((state) => state.players);
  const rounds = useStore((state) => state.rounds);
  const groups = useStore((state) => state.groups);
  const round_groups = useStore((state) => state.roundGroups);

  const [loading, setLoading] = useState(false);
  const [matches, setMatches] = useState<Match[]>([]);

  const { loading: AppLoading, loadAllData } = useLoadAllData();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current || null,
    bodyClass: 'printClass',
  });

  useEffect(() => {
    loadAllData();
  }, []);

  const fetchMatches = () => {
    setLoading(true);
    supabase
      .from('matches')
      .select()
      .eq('groupId', groupId)
      .then((response) => {
        setMatches(response.data || []);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMatches();
  }, [groupId]);

  if (loading || AppLoading) {
    return (
      <Pane>
        <Spinner marginX='auto' marginY={120} />
      </Pane>
    );
  }

  if (matches.length === 0) {
    return <div>No matches generated for selected group</div>;
  }

  const currentGroup = groups.find((g) => g.id === Number(groupId));
  const currentRoundId = round_groups.find(
    (cg) => cg.groupId === currentGroup?.id
  )?.roundId;

  const currentRound = rounds.find((r) => `${r.id}` === `${currentRoundId}`);

  return (
    <>
      <div className='home-page-image-container'>
        <img src='/logo.png' height='100px'></img>
      </div>
      <Ribbon text={currentRound ? currentRound.name : ''} />
      <div className='matchesContainer'>
        <div className='printThis' ref={componentRef}>
          <div className='groupTitle'>
            <div className='matches-group-name'>
              {currentGroup?.name} scores
            </div>
            <div className='matches-group-text'>
              Number of matches: <b>{matches.length} </b>
            </div>
            <div className='matches-group-text'>
              Play to: <b>{currentGroup?.playTill}</b> | Win By:{' '}
              <b>{currentGroup?.winBy}</b>{' '}
            </div>
          </div>
          <div className='printableContainer'>
            {matches
              .sort((a, b) => (a.id > b.id ? 1 : -1))
              .map((match) => (
                <MatchComponent
                  match={match}
                  players={players}
                  key={match.id}
                  groupId={groupId}
                  fetchMatches={fetchMatches}
                  disableSubmit={match.locked}
                  playTill={currentGroup?.playTill}
                  winBy={currentGroup?.winBy}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Matches;
