import { useCallback } from 'react';

import { Tab } from './Category';
import useStore from './useStore';
import { supabase } from './utils';

const useBuildWinners = () => {
  const rounds = useStore((state) => state.rounds);
  const groups = useStore((state) => state.groups);
  const setFinals = useStore((state) => state.setFinals);

  return useCallback(
    async (categoryId: number, tabs: Tab[]) => {
      if (tabs.length === 0) {
        return;
      }

      const lastRound = tabs[0];
      const groupForThisRound = lastRound.groups;

      if (groupForThisRound.length !== 1) {
        alert('There should be only one group in the last round');
      }

      const winners = groupForThisRound[0].players
        .sort((a, b) => b.won - a.won)
        .sort((a, b) => b.difference - a.difference);

      const goldWinner = winners[0];
      const silverWinner = winners[1];
      const bronzeWinner = winners[2];

      alert(
        'Winners are: ' +
          goldWinner.name +
          ', ' +
          silverWinner.name +
          ', ' +
          bronzeWinner.name
      );
      const finalMatch1 = {
        categoryId,
        matchIdentifier: 0,
        team1: winners[0].playerId,
        team2: winners[1].playerId,
        score1: 11,
        score2: 10,
        completed: true,
        winner: winners[0].playerId,
        loser: winners[1].playerId,
      };

      const finalMatch2 = {
        categoryId,
        matchIdentifier: 1,
        team1: winners[2].playerId,
        team2: winners[3].playerId,
        score1: 11,
        score2: 10,
        completed: true,
        winner: winners[2].playerId,
        loser: winners[3].playerId,
      };
      const finals = [finalMatch1, finalMatch2];

      const finalsResponse = await supabase
        .from('finals')
        .insert(finals)
        .select('*');

      setFinals(finalsResponse.data || []);
      return;
    },

    [setFinals]
  );
};

export default useBuildWinners;
