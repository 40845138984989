import {
  Button,
  Card,
  Pane,
  TextInput,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import { Group, Match, Player, Score } from './types';
import { ChangeEvent, useContext, useState } from 'react';
import { supabase } from './utils';
import { AppContext } from './AppContext';
import useIsAdmin from './useIsAdmin';

const MatchComponent = ({
  match,
  players,
  groupId,
  fetchMatches,
  disableSubmit = true,
  playTill,
  winBy,
}: {
  match: Match;
  players: Player[];
  groupId?: string;
  fetchMatches: () => void;
  disableSubmit: boolean;
  playTill?: number;
  winBy?: number;
}) => {
  const [score1, setScore1] = useState<number>(match.score1);
  const [score2, setScore2] = useState<number>(match.score2);
  const [loading, setLoading] = useState(false);
  const isAdmin = useIsAdmin();

  const bye = match.bye
    ? players.find((player) => player.id === Number(match.bye))
    : undefined;

  const opponent1 = match.team1
    .split(';')
    .map((t1) => players.find((p) => p.id === Number(t1)));

  const opponent2 = match.team2
    .split(';')
    .map((t2) => players.find((p) => p.id === Number(t2)));

  const winner =
    match.winner &&
    match.winner
      .split(';')
      .map((t1) => players.find((p) => p.id === Number(t1)));

  const handleSubmit = async () => {
    if (!playTill || !winBy) {
      alert(`Invalid score:No play till or win by information available`);
      return;
    }
    if (score1 < playTill && score2 < playTill) {
      alert(
        `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
      );
      return;
    }

    const diff = Math.abs(score1 - score2);

    if (score1 === playTill || score2 === playTill) {
      if (diff < winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    if (score1 > playTill || score2 > playTill) {
      if (diff !== winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    const currentMatch = await supabase
      .from('matches')
      .select()
      .eq('id', match.id)
      .then((response) => (response.data ? response.data[0] : undefined));

    if (!currentMatch || currentMatch.locked) {
      toaster.danger('Score already entered');
      return;
    }
    const data: Score[] = [];

    const opponent1Players = match.team1.split(';');
    const opponent2Players = match.team2.split(';');

    opponent1Players.forEach((p) => {
      const forScore = Number(score1);
      const against = Number(score2);
      const difference = forScore - against;

      data.push({
        matchId: Number(match.id),
        groupId: Number(groupId),
        playerId: Number(p),
        for: forScore,
        against,
        difference,
      });
    });

    opponent2Players.forEach((p) => {
      const forScore = Number(score2);
      const against = Number(score1);
      const difference = forScore - against;

      data.push({
        matchId: Number(match.id),
        groupId: Number(groupId),
        playerId: Number(p),
        for: forScore,
        against,
        difference,
      });
    });

    try {
      setLoading(true);
      const response = await supabase.from('scores').upsert(data).select();
      const matchResponse = await supabase
        .from('matches')
        .update({
          locked: true,
          score1,
          score2,
          winner: score1 > score2 ? match.team1 : match.team2,
        })
        .eq('id', match.id);
      toaster.success('Scores saved');
      fetchMatches();
      setLoading(false);
    } catch {
      setLoading(false);
      toaster.danger('Error saving score');
    }
  };

  return (
    <Card elevation={1} className='matchContainer'>
      <Pane
        style={{
          backgroundColor: match.locked ? '#189C41' : '#18669c26',
          color: match.locked ? 'white' : '#707071',
        }}
        className='matchTitle'
      >
        <h5>
          Match &nbsp;
          {match.id}
        </h5>
      </Pane>
      {bye && (
        <Pane
          style={{
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '0 30px',
          }}
          background='gray200'
        >
          <div style={{ fontSize: '12px' }}>Bye: {bye.name}</div>
        </Pane>
      )}

      <Pane className='matchDescription' style={{ marginTop: '10px' }}>
        <Pane className='opponent'>
          <div
            className={
              score1 > score2 ? 'opponentPlayersWinner' : 'opponentPlayers'
            }
          >
            {opponent1.map((p, index) => (
              <div key={p!.id}>
                <div style={{ display: 'flex' }}>
                  &nbsp;{p!.name}
                  {index !== opponent1.length - 1 && <div>&nbsp;&</div>}
                </div>
              </div>
            ))}
          </div>
          <Pane className='opponentScore'>
            <TextInput
              disabled={match.locked}
              value={score1}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setScore1(Number(e.target.value))
              }
              width={'100px'}
              className={score1 > score2 ? 'opponentScoreWinner' : ''}
            />
          </Pane>
        </Pane>
        <div
          style={{ marginTop: '15px', marginBottom: '15px', color: '#666666' }}
        >
          vs
        </div>
        <Pane className='opponent'>
          <div
            className={
              score2 > score1 ? 'opponentPlayersWinner' : 'opponentPlayers'
            }
          >
            {opponent2.map((p, index) => (
              <div key={p!.id}>
                <div style={{ display: 'flex' }}>
                  &nbsp;{p!.name}
                  {index !== opponent1.length - 1 && <div>&nbsp;&</div>}
                </div>
              </div>
            ))}
          </div>
          <Pane className='opponentScore'>
            <TextInput
              disabled={match.locked}
              value={score2}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setScore2(Number(e.target.value))
              }
              width={'100px'}
              className={score2 > score1 ? 'opponentScoreWinner' : ''}
            />
          </Pane>
        </Pane>
        <Button
          onClick={handleSubmit}
          disabled={match.locked || disableSubmit || !isAdmin}
          style={{
            height: '48px',
            color: 'white',
            width: '100%',
            fontSize: '16px',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
            marginTop: '20px',
          }}
          className='submitButton'
          background={
            match.locked || disableSubmit || !isAdmin ? '#18669c26' : '#18669C'
          }
        >
          Submit score
        </Button>
      </Pane>
    </Card>
  );
};

export default MatchComponent;
