import { useState } from 'react';

import useStore from './useStore';
import { supabase } from './utils';

const useLoadAllData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const setTourneys = useStore((state) => state.setTourneys);
  const setCategories = useStore((state) => state.setCategories);
  const setPlayers = useStore((state) => state.setPlayers);
  const setRounds = useStore((state) => state.setRounds);
  const setGroups = useStore((state) => state.setGroups);
  const setRoundGroups = useStore((state) => state.setRoundGroups);
  const setMatches = useStore((state) => state.setMatches);
  const setScores = useStore((state) => state.setScores);
  const setSemis = useStore((state) => state.setSemis);
  const setFinals = useStore((state) => state.setFinals);

  const loadAllData = async () => {
    setLoading(true);

    const tourneysResponse = await supabase
      .from('tourneys')
      .select()
      .then((response) => {
        return response.data;
      });

    setTourneys(tourneysResponse || []);

    const categoriesResponse = await supabase
      .from('categories')
      .select()
      .then((response) => {
        return response.data;
      });

    setCategories(categoriesResponse || []);

    const groupsResponse = await supabase
      .from('groups')
      .select()
      .then((response) => {
        return response.data;
      });

    setGroups(groupsResponse || []);

    const roundsResponse = await supabase
      .from('rounds')
      .select()
      .then((response) => {
        return response.data;
      });

    setRounds(roundsResponse || []);

    const playersResponse = await supabase
      .from('players')
      .select()
      .then((response) => {
        return response.data;
      });

    setPlayers(playersResponse || []);

    const scoresResponse = await supabase
      .from('scores')
      .select()
      .then((response) => {
        return response.data;
      });

    setScores(scoresResponse || []);

    const round_groupsResponse = await supabase
      .from('round_groups')
      .select()
      .then((response) => response.data);

    setRoundGroups(round_groupsResponse || []);

    const matchesResponse = await supabase
      .from('matches')
      .select()
      .then((response) => response.data);

    setMatches(matchesResponse || []);

    const semisResponse = await supabase
      .from('semis')
      .select()
      .then((response) => response.data);

    setSemis(semisResponse || []);

    setLoading(false);

    const finalsResponse = await supabase
      .from('finals')
      .select()
      .then((response) => response.data);

    setFinals(finalsResponse || []);

    setLoading(false);
  };

  return { loading, error, loadAllData };
};

export default useLoadAllData;
