import { createClient } from '@supabase/supabase-js';

import { REACT_APP_SUPABASE_KEY, REACT_APP_SUPABASE_URL } from './constants';

const supabaseUrl = REACT_APP_SUPABASE_URL;
const supabaseKey = REACT_APP_SUPABASE_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export const generateSinglesRoundRobin = (players: number[]) => {
  const matches = [];

  for (let i = 0; i < players.length - 1; i++) {
    for (let j = i + 1; j < players.length; j++) {
      matches.push([players[i], players[j]]);
    }
  }

  return matches;
};

export const generateDoublesRoundRobin = (players: number[]) => {
  const matches = [];

  if (players.length === 4) {
    matches.push([
      `${players[0]};${players[1]}`,
      `${players[2]};${players[3]}`,
    ]);
    matches.push([
      `${players[0]};${players[2]}`,
      `${players[1]};${players[3]}`,
    ]);
    matches.push([
      `${players[0]};${players[3]}`,
      `${players[1]};${players[2]}`,
    ]);

    return matches;
  }

  if (players.length === 5) {
    matches.push([
      `${players[0]};${players[1]}`,
      `${players[2]};${players[3]}`,
      `${players[4]}`,
    ]);

    matches.push([
      `${players[0]};${players[2]}`,
      `${players[3]};${players[4]}`,
      `${players[1]}`,
    ]);

    matches.push([
      `${players[0]};${players[3]}`,
      `${players[1]};${players[4]}`,
      `${players[2]}`,
    ]);

    matches.push([
      `${players[0]};${players[4]}`,
      `${players[1]};${players[2]}`,
      `${players[3]}`,
    ]);

    matches.push([
      `${players[1]};${players[3]}`,
      `${players[2]};${players[4]}`,
      `${players[0]}`,
    ]);

    return matches;
  }

  return [];
};

export const getTourneyText = (tourney: string) => {
  switch (tourney) {
    case 'juniors':
      return "Junior's";
    case 'mens':
      return "Men's";
    case 'womens':
      return "Women's";
    case 'seniors':
      return "Senior's";
    case 'mixedDoubles':
      return 'Mixed Doubles';

    default:
      return '';
  }
};
