import { useEffect, useState } from 'react';
import { Pane, TextInput, Button, Select } from 'evergreen-ui';
import './AdminButton.css';
import { useNavigate } from 'react-router-dom';
import useIsAdmin from './useIsAdmin';
import useStore from './useStore';
import { supabase } from './utils';

const AdminButton = () => {
  const isAdmin = useIsAdmin();
  const [password, setPassword] = useState('');
  const [isError, setError] = useState(false);
  const matches = useStore((state) => state.matches);

  const [selectedMatch, setSelectedMatch] = useState(0);

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (password === 'mcpicklers123') {
      localStorage.setItem('MCPicklers-Admin', 'true');
    }
    window.location.reload();
  };

  const handleReset = async () => {
    const match = matches.find((m) => {
      if (Number(m.id) === Number(selectedMatch)) {
        return true;
      } else {
        return false;
      }
    });

    const newMatch = {
      ...match,
      score1: null,
      score2: null,
      winner: null,
      locked: null,
    };
    await supabase.from('scores').delete().in('matchId', [newMatch.id]);
    await supabase
      .from('matches')
      .upsert([newMatch])
      .then(() => {
        alert('scores reset');
      });
  };

  useEffect(() => {
    setSelectedMatch(matches[0]?.id);
  }, [matches]);

  return (
    <Pane className='admin-container'>
      {!isAdmin && (
        <>
          <TextInput
            className='admin-password-input'
            name='password'
            placeholder='Entry admin password here'
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
              if (isError) {
                setError(false);
              }
            }}
            isInvalid={isError}
          />
          {isError && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              Incorrect password
            </div>
          )}
          <Button className='admin-submit-button' onClick={handleSubmit}>
            Login
          </Button>
        </>
      )}
      {isAdmin && (
        <div onClick={() => navigate('/home')} className='admin-password-input'>
          Logged in, back to home
        </div>
      )}
      {isAdmin && (
        <div className='resetScore'>
          Reset scores
          <div className='resetScore-Width'>
            <Select
              value={selectedMatch}
              onChange={(e: any) => setSelectedMatch(e.target.value)}
            >
              {matches
                .sort((a, b) => b.id - a.id)
                .map((match, index) => (
                  <option key={index} value={match.id}>
                    {match.id}
                  </option>
                ))}
            </Select>
          </div>
          <Button onClick={handleReset}>Reset</Button>
        </div>
      )}
    </Pane>
  );
};

export default AdminButton;
