import {
  Button,
  Card,
  Pane,
  TextInput,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import useStore from './useStore';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { supabase } from './utils';
import './Finals.css';
import useIsAdmin from './useIsAdmin';

const Finals = ({
  categoryId,
  forceHide,
}: {
  categoryId: number;
  forceHide: boolean;
}) => {
  const finals = useStore((state) => state.finals);
  const players = useStore((state) => state.players);
  const setFinals = useStore((state) => state.setFinals);
  const isAdmin = useIsAdmin();

  const finalsForCategory = useMemo(
    () =>
      finals
        .filter((final) => final.categoryId === categoryId)
        .sort((a, b) => a.id - b.id),
    [finals, categoryId]
  );

  const didAllFinalsComplete = useMemo(() => {
    return finalsForCategory.every((f) => f.completed);
  }, [finalsForCategory]);

  const [show, setShow] = useState(true);

  // useEffect(() => {
  //   if (
  //     finalsForCategory.length === 0 &&
  //     finalsForCategory.every((f) => f.completed)
  //   ) {
  //     setShow(false);
  //   }
  // }, [finalsForCategory]);

  const [matchIdScores, setMatchIdScores] = useState<{
    [key: number]: { score1: number; score2: number };
  }>({
    [finalsForCategory[0]?.id]: {
      score1: finalsForCategory[0]?.score1,
      score2: finalsForCategory[0]?.score2,
    },
    [finalsForCategory[1]?.id]: {
      score1: finalsForCategory[1]?.score1,
      score2: finalsForCategory[1]?.score2,
    },
  });

  useEffect(() => {
    setMatchIdScores({
      [finalsForCategory[0]?.id]: {
        score1: finalsForCategory[0]?.score1,
        score2: finalsForCategory[0]?.score2,
      },
      [finalsForCategory[1]?.id]: {
        score1: finalsForCategory[1]?.score1,
        score2: finalsForCategory[1]?.score2,
      },
    });
  }, [finalsForCategory]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (matchId: number) => {
    const final = finals.find((final) => final.id === matchId);
    if (!final) {
      return;
    }

    const { playTill, winBy } = final;
    const score1 = matchIdScores[final?.id]?.score1;
    const score2 = matchIdScores[final?.id]?.score2;

    if (!playTill || !winBy) {
      alert(`Invalid score:No play till or win by information available`);
      return;
    }
    if (score1 < playTill && score2 < playTill) {
      alert(
        `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
      );

      return;
    }

    const diff = Math.abs(score1 - score2);

    if (score1 === playTill || score2 === playTill) {
      if (diff < winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    if (score1 > playTill || score2 > playTill) {
      if (diff !== winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    try {
      setLoading(true);
      const score1 = matchIdScores[final?.id]?.score1;
      const score2 = matchIdScores[final?.id]?.score2;

      const updatedFinals = await supabase
        .from('finals')
        .update({
          completed: true,
          score1,
          score2,
          winner: score1 > score2 ? final.team1 : final.team2,
          loser: score1 > score2 ? final.team2 : final.team1,
        })
        .eq('id', final.id)
        .select('*');
      toaster.success('Scores saved');
      setLoading(false);
      window.location.reload();
    } catch {
      setLoading(false);
      toaster.danger('Error saving score');
    }
  };

  if (finalsForCategory.length === 0 || forceHide) {
    return null;
  }

  return (
    <div className='finals-container'>
      <div className='finals-title'>
        <div className='finals-text'>Finals</div>
        {didAllFinalsComplete && (
          <div
            className='finals-scores'
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? 'Hide scores' : 'Show scores'}
          </div>
        )}
      </div>

      <div
        className='finalsMatches'
        style={{
          opacity: show ? 1 : 0,
          transition: 'all .2s ease-in',
          height: show ? 'auto' : '0px',
          visibility: show ? 'visible' : 'hidden',
        }}
      >
        {finalsForCategory.map((final) => {
          return (
            <Card elevation={1} className='matchContainer' key={final.id}>
              <Pane
                style={{
                  backgroundColor: final.completed ? '#189C41' : '#18669c26',
                  color: final.completed ? 'white' : '#707071',
                }}
                className='matchTitle'
              >
                <h5>
                  Match &nbsp;
                  {final.id}
                </h5>
              </Pane>

              <Pane className='matchDescription' style={{ marginTop: '10px' }}>
                <Pane className='opponent'>
                  <Pane
                    className={
                      final.score1 > final.score2
                        ? 'opponentPlayersWinner'
                        : 'opponentPlayers'
                    }
                  >
                    {players.find((p) => p.id === Number(final.team1))?.name}
                  </Pane>
                  <Pane className='opponentScore'>
                    <TextInput
                      disabled={final.completed}
                      value={matchIdScores[final.id]?.score1}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setMatchIdScores((prev) => ({
                          ...prev,
                          [final.id]: {
                            score1: Number(e.target.value),
                            score2: prev[final.id]?.score2,
                          },
                        }))
                      }
                      width={'100px'}
                      className={
                        final.score1 > final.score2 ? 'opponentScoreWinner' : ''
                      }
                    />
                  </Pane>
                </Pane>
                <div style={{ marginBottom: '10px' }}>vs</div>
                <Pane className='opponent'>
                  <Pane
                    className={
                      final.score2 > final.score1
                        ? 'opponentPlayersWinner'
                        : 'opponentPlayers'
                    }
                  >
                    {players.find((p) => p.id === Number(final.team2))?.name}
                  </Pane>
                  <Pane className='opponentScore'>
                    <TextInput
                      disabled={final.completed}
                      value={matchIdScores[final.id]?.score2}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setMatchIdScores((prev) => ({
                          ...prev,
                          [final.id]: {
                            score1: prev[final.id]?.score1,
                            score2: Number(e.target.value),
                          },
                        }))
                      }
                      width={'100px'}
                      className={
                        final.score2 > final.score1 ? 'opponentScoreWinner' : ''
                      }
                    />
                  </Pane>
                </Pane>
                <Button
                  onClick={() => handleSubmit(final.id)}
                  disabled={final.completed || loading || !isAdmin}
                  style={{
                    height: '48px',
                    color: 'white',
                    width: '100%',
                    fontSize: '16px',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '0px',
                    marginTop: '20px',
                  }}
                  className='submitButton'
                  background={final.completed ? '#18669c26' : '#18669C'}
                >
                  Submit score
                </Button>
              </Pane>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default Finals;
