import { create } from 'zustand';

import {
  Category,
  Group,
  Match,
  Player,
  Round,
  Round_Groups,
  Score,
  Tourney,
  Semi,
  Final,
} from './types';

type State = {
  tourneys: Tourney[];
  setTourneys: (tourneys: Tourney[]) => void;

  categories: Category[];
  setCategories: (categories: Category[]) => void;

  rounds: Round[];
  setRounds: (round: Round[]) => void;

  groups: Group[];
  setGroups: (groups: Group[]) => void;

  roundGroups: Round_Groups[];
  setRoundGroups: (roundGroups: Round_Groups[]) => void;

  players: Player[];
  setPlayers: (players: Player[]) => void;

  matches: Match[];
  setMatches: (matches: Match[]) => void;

  scores: Score[];
  setScores: (scores: Score[]) => void;

  semis: Semi[];
  setSemis: (semis: Semi[]) => void;

  finals: Final[];
  setFinals: (finals: Final[]) => void;
};

const useStore = create<State>((set) => ({
  tourneys: [],
  setTourneys: (tourneys: Tourney[]) => set((state: State) => ({ tourneys })),

  categories: [],
  setCategories: (categories: Category[]) =>
    set((state: State) => ({ categories })),

  rounds: [],
  setRounds: (rounds: Round[]) => set((state: State) => ({ rounds })),

  groups: [],
  setGroups: (groups: Group[]) => set((state: State) => ({ groups })),

  roundGroups: [],
  setRoundGroups: (roundGroups: Round_Groups[]) =>
    set((state: State) => ({ roundGroups })),

  players: [],
  setPlayers: (players: Player[]) => set((state: State) => ({ players })),

  matches: [],
  setMatches: (matches: Match[]) => set((state: State) => ({ matches })),

  scores: [],
  setScores: (scores: Score[]) => set((state: State) => ({ scores })),

  semis: [],
  setSemis: (semis: Semi[]) => set((state: State) => ({ semis })),

  finals: [],
  setFinals: (finals: Final[]) => set((state: State) => ({ finals })),
}));

export default useStore;
