import { useLocation, useNavigate } from 'react-router-dom';

import './Ribbon.css';
import { Button, Link } from 'evergreen-ui';

const Ribbon = ({
  text,
  showBackButton = true,
}: {
  text: string;
  showBackButton?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className='ribbon-container'>
        {showBackButton && (
          <div
            className='ribbon-back-button'
            onClick={() => {
              console.log('Click');
              navigate(-1);
            }}
          ></div>
        )}
        <div className='ribbon-text'>{text}</div>
      </div>
      {location.pathname !== '/' && (
        <div className='ribbon-link-container'>
          <Button onClick={() => navigate('/')} className='ribbon-link-text'>
            Home
          </Button>
        </div>
      )}
      {location.pathname === '/' && (
        <div className='ribbon-link-container'>
          <Button
            onClick={() => navigate('/schedule')}
            className='ribbon-link-text'
          >
            Schedule
          </Button>
        </div>
      )}
    </>
  );
};

export default Ribbon;
