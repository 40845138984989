import {
  Button,
  Card,
  Pane,
  TextInput,
  TextInputField,
  toaster,
} from 'evergreen-ui';
import useStore from './useStore';
import { ChangeEvent, useEffect, useState } from 'react';
import { supabase } from './utils';
import './Semis.css';
import useIsAdmin from './useIsAdmin';

const Semis = ({ categoryId }: { categoryId: number }) => {
  const semis = useStore((state) => state.semis);
  const players = useStore((state) => state.players);
  const setSemis = useStore((state) => state.setSemis);
  const isAdmin = useIsAdmin();

  const [show, setShow] = useState(true);

  const semisForCategory = semis
    .filter((semi) => semi.categoryId === categoryId)
    .sort((a, b) => a.id - b.id);

  // useEffect(() => {
  //   if (
  //     semisForCategory.length === 0 &&
  //     semisForCategory.every((f) => f.completed)
  //   ) {
  //     setShow(false);
  //   }
  // }, [semisForCategory]);

  const [matchIdScores, setMatchIdScores] = useState<{
    [key: number]: { score1: number; score2: number };
  }>({
    [semisForCategory[0]?.id]: {
      score1: semisForCategory[0]?.score1,
      score2: semisForCategory[0]?.score2,
    },
    [semisForCategory[1]?.id]: {
      score1: semisForCategory[1]?.score1,
      score2: semisForCategory[1]?.score2,
    },
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (matchId: number) => {
    const semi = semis.find((semi) => semi.id === matchId);
    if (!semi) {
      return;
    }

    const { playTill, winBy } = semi;
    const score1 = matchIdScores[semi?.id]?.score1;
    const score2 = matchIdScores[semi?.id]?.score2;

    if (!playTill || !winBy) {
      alert(`Invalid score:No play till or win by information available`);
      return;
    }
    if (score1 < playTill && score2 < playTill) {
      alert(
        `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
      );
      return;
    }

    const diff = Math.abs(score1 - score2);

    if (score1 === playTill || score2 === playTill) {
      if (diff < winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    if (score1 > playTill || score2 > playTill) {
      if (diff !== winBy) {
        alert(
          `Invalid score: Match should be played to ${playTill} and won by ${winBy}`
        );
        return;
      }
    }

    try {
      setLoading(true);

      const updatedSemis = await supabase
        .from('semis')
        .update({
          completed: true,
          score1,
          score2,
          winner: score1 > score2 ? semi.team1 : semi.team2,
          loser: score1 > score2 ? semi.team2 : semi.team1,
        })
        .eq('id', semi.id);

      toaster.success('Scores saved');
      setLoading(false);
      window.location.reload();
      // setSemis(updatedSemis.data!);
    } catch {
      setLoading(false);
      toaster.danger('Error saving score');
    }
  };

  if (semisForCategory.length === 0) {
    return null;
  }

  const didAllSemisComplete = semisForCategory.every((f) => f.completed);

  return (
    <div className='semis-container'>
      <div className='semis-title'>
        <div className='semis-text'>Semi Finals</div>
        {didAllSemisComplete && (
          <div
            className='finals-scores'
            onClick={() => setShow((prev) => !prev)}
          >
            {show ? 'Hide scores' : 'Show scores'}
          </div>
        )}
      </div>
      {
        <div
          className='semisContainer'
          style={{
            opacity: show ? 1 : 0,
            transition: 'all .2s ease-in',
            height: show ? 'auto' : '0px',
            visibility: show ? 'visible' : 'hidden',
          }}
        >
          {semisForCategory.map((semi) => {
            return (
              <Card elevation={1} className='matchContainer'>
                <Pane
                  style={{
                    backgroundColor: semi.completed ? '#189C41' : '#18669c26',
                    color: semi.completed ? 'white' : '#707071',
                  }}
                  className='matchTitle'
                >
                  <h5>
                    Match &nbsp;
                    {semi.id}
                  </h5>
                </Pane>

                <Pane
                  className='matchDescription'
                  style={{ marginTop: '10px' }}
                >
                  <Pane className='opponent'>
                    <Pane
                      className={
                        semi.score1 > semi.score2
                          ? 'opponentPlayersWinner'
                          : 'opponentPlayers'
                      }
                    >
                      {players.find((p) => p.id === Number(semi.team1))?.name}
                    </Pane>
                    <Pane className='opponentScore'>
                      <TextInput
                        disabled={semi.completed}
                        value={matchIdScores[semi.id]?.score1}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setMatchIdScores((prev) => ({
                            ...prev,
                            [semi.id]: {
                              score1: Number(e.target.value),
                              score2: prev[semi.id]?.score2,
                            },
                          }))
                        }
                        width={'100px'}
                        className={
                          semi.score1 > semi.score2 ? 'opponentScoreWinner' : ''
                        }
                      />
                    </Pane>
                  </Pane>
                  <div style={{ marginBottom: '10px' }}>vs</div>
                  <Pane className='opponent'>
                    <Pane
                      className={
                        semi.score2 > semi.score1
                          ? 'opponentPlayersWinner'
                          : 'opponentPlayers'
                      }
                    >
                      {players.find((p) => p.id === Number(semi.team2))?.name}
                    </Pane>
                    <Pane className='opponentScore'>
                      <TextInput
                        disabled={semi.completed}
                        value={matchIdScores[semi.id]?.score2}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setMatchIdScores((prev) => ({
                            ...prev,
                            [semi.id]: {
                              score1: prev[semi.id]?.score1,
                              score2: Number(e.target.value),
                            },
                          }))
                        }
                        width={'100px'}
                        className={
                          semi.score2 > semi.score1 ? 'opponentScoreWinner' : ''
                        }
                      />
                    </Pane>
                  </Pane>
                  <Button
                    onClick={() => handleSubmit(semi.id)}
                    disabled={semi.completed || loading || !isAdmin}
                    style={{
                      height: '48px',
                      color: 'white',
                      width: '100%',
                      fontSize: '16px',
                      borderTopLeftRadius: '0px',
                      borderTopRightRadius: '0px',
                      marginTop: '20px',
                    }}
                    className='submitButton'
                    background={semi.completed ? '#18669c26' : '#18669C'}
                  >
                    Submit score
                  </Button>
                </Pane>
              </Card>
            );
          })}
        </div>
      }
    </div>
  );
};

export default Semis;
